import { dateFormat } from "@/components-js/dateFormat";
import { ru } from "date-fns/locale";

export const formattedTime = (shift, isStart = true) => {
  const date = new Date();

  date.setHours(isStart ? shift?.time_begin ?? shift?.hour_begin : shift?.time_end ?? shift?.hour_end);
  date.setMinutes(new Date((isStart ? shift?.utime_begin : shift?.utime_end) * 1000).getMinutes());

  return dateFormat(date, "k:mm", { locale: ru });
};

export const formattedSummarizeWorkTime = (shift, onlyValue = false) => {
  const startDate = new Date(shift?.utime_begin * 1000);
  const endDate = new Date(shift?.utime_end * 1000);

  if (Number(shift.time_end) > Number(shift.time_begin)) {
    const diffHours = (+shift.time_end === 0 ? 24 : shift.time_end) - shift.time_begin;
    const diffMinutes = endDate.getMinutes() - startDate.getMinutes();

    const finishHour = (diffMinutes >= 0 ? diffHours : diffHours - 1) + shift.hours_add;
    const finishMinute = diffMinutes < 0 ? 60 + diffMinutes : diffMinutes;

    const formattedText = `${finishHour}ч` + (finishMinute === 0 ? "" : ` ${finishMinute}м`);

    return onlyValue
      ? {
          hours: finishHour,
          minutes: finishMinute,
        }
      : formattedText;
  } else {
    const diffHours = 24 - Number(shift.time_begin) + Number(shift.time_end);
    const diffMinutes = endDate.getMinutes() - startDate.getMinutes();

    const finishHour = (diffMinutes >= 0 ? diffHours : diffHours - 1) + shift.hours_add;
    const finishMinute = diffMinutes < 0 ? 60 + diffMinutes : diffMinutes;

    const formattedText = `${finishHour}ч` + (finishMinute === 0 ? "" : ` ${finishMinute}м`);

    return onlyValue
      ? {
          hours: finishHour,
          minutes: finishMinute,
        }
      : formattedText;
  }
};

export const stringToArray = str => {
  return str
    ?.trim()
    .replace(" ", "")
    .split(",")
    .filter(coworker => !!coworker);
};
