<template>
  <div class="list-group-item" :class="{ __active: item.active === 1 }">
    <div class="list-group-item__time">
      {{ formattedTime(item) }}&mdash;{{ formattedTime(item, false) }}
      <base-tag v-if="item.turn_order === 0" class="list-group-item__tag" />
      <base-tag v-if="item.job_icon" class="list-group-item__tag" :icon="item.job_icon" />
    </div>
    <div class="list-group-item__icon"></div>
    <div class="list-group-item__avatar">
      <base-avatar
        flex-direction="row-reverse"
        text-align="end"
        name-color="#1E293B"
        :screen-size-dependency="false"
        :show-job-title="!isMobile"
        font-weight="500"
        :info="item"
      />
    </div>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";
import { offsetApply } from "@/components-js/timeOffset";
import BaseAvatar from "@/components/BaseAvatar";
import { isMobile } from "@/lib/helpers/checkDevice";
import BaseTag from "@/components/BaseTag/BaseTag.vue";
import { formattedTime } from "@/views/ShiftListPage/helpers";

export default defineComponent({
  name: "ListGroupItem",
  components: { BaseTag, BaseAvatar },
  props: {
    item: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    isMobile
  },
  setup() {
    const convertCurrentTime = (h, l) => {
      return offsetApply(h, l);
    }
    return {
      convertCurrentTime,
      formattedTime,
    }
  }
})
</script>
<style lang="scss" scoped>
.list-group-item {
  background: #f1f5f9;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 24px;
  border-radius: 5px;
  padding: 4px 16px;
  &.__active {
    border-left: 5px solid #ffa826;
    padding-left: 11px;
  }
  &__time {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
  &__tag {
    margin-left: 24px;
  }
  &__avatar {
    ::v-deep {
      .base-avatar {
        &__img {
          width: 40px;
          height: 40px;
        }

        &__name {
          font-size: 16px;
        }

        &__job-title {
          line-height: 16px;
        }
      }
    }
  }
  @media (max-width: 1440px) {
    & {
      margin-top: 16px;
    }
  }
  @media (max-width: 980px) {
    & {
      margin-top: 12px;
      padding: 12px 16px;
    }
    &__time {
      font-family: Inter, serif;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20px;
    }
    &__avatar {
      .base-avatar {
        &__img {
          width: 24px;
          height: 24px;
        }
        &__name {
          font-size: 14px;
        }
      }
    }
  }
}
</style>
