<template>
  <div class="base-tag" :class="{ __default: !!icon }">
    <div v-if="!!icon" class="base-tag__icon">{{ icon }}</div>
    <object v-else class="base-tag__object" type="image/svg+xml" :data="icoPath">
      <img src="ico.png" alt="svg ico" />
    </object>
  </div>
</template>
<script lang="js">
import { defineComponent } from "vue";

export default defineComponent({
  name: "BaseTag",
  props: {
    icon: {
      type: String,
      default: ""
    }
  },
  setup() {
    const icoPath = require('@/assets/junior.svg');
    return { icoPath };
  }
})
</script>
<style lang="scss" scoped>
.base-tag {
  display: flex;
  width: max-content;
  padding: 0 4px;
  background-color: #dbedff;
  border-radius: 5px;
  &.__default {
    background: var(--color-secondary-light-bg);
  }
  &__icon {
    align-items: center;
    justify-content: center;
    height: 24px;
    font-size: 12px;
    min-width: 16px;
  }

  &__object {
    background-color: #dbedff;
    width: 16px;
    height: 24px;
  }
  @media (max-width: 980px) {
    padding: 2px 4px;
    &__object {
      width: 12px;
      height: 16px;
    }
    &__icon {
      align-items: center;
      height: 16px;
      width: 12px;
      font-size: 10px;
    }
  }
}
</style>
